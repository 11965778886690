@import url(https://fonts.googleapis.com/css?family=Roboto:300,400);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}


/* Fonts */


.compare_add{
  display: flex;
  text-align: center;
  font-size: 12px;
  align-items: center;
  align-content:center;
  background-color: #e21e1e;
  vertical-align: center;
}
.text_id {
  width:90%;
  background-color: #f0f0f0;
  text-align: left;
  padding-top:5px;
  padding-bottom:10px;
  border: 1px solid #b6b3b3;
  font-size: 12px;
}

.byte_size {
  font-size: 12px;
  width: 100px;
  display:table;
  align-content:right;
  margin-left: auto;
  text-align: right;
  
}

.text_content_seperator{
  padding:8px;
  padding-top:20px;
  border: 0px solid #ffffff;
}
.text_content {
  width:90%;
  background-color: #ffffff;
  text-align: left;
  padding-top:5px;
  padding-bottom:10px;
  border: 1px solid #b8b8b8;
  font-size: 14px;
}

.text_content_row {
  width:90%;
  background-color: #ffffff;
  text-align: left;
  padding-top:5px;
  padding-bottom:10px;
  border: 1px solid #ffffff;
  font-size: 14px;
}

.content_table {
  width:90%;
  margin-left: 10px;
  background-color: #ffffff;
  text-align: left;
  border: 0px solid #ffffff;
  font-size: 14px;
  border-collapse: collapse;
}
.App {
  text-align: center;
}

.App-logo {
  /* animation: App-logo-spin infinite 20s linear; */
  height: 250px;
  margin: 2em;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

div.mobile__pdf__container > div#viewerContainer {
    position: relative;
    top: 0;
}

.mobile__pdf__container > .pdfViewer {
    position: relative;
}

.video-container {
    position:relative;
}

.video {
    height:100%;
    width:100%;
    max-width:600px;
}

.localVideo {
    width: 120px;
    height: 120px;
    position: absolute;
    top:0;
    left:0;
    z-index: 1;
}

.remoteVideo {
    width:100%;
    max-width:600px;
    position: relative;
}

.localVideo > video {
    width: 100%;
}

.remoteVideo > video {
    width: 100%;
}

.label {
    font-family: sans-serif;
    padding: 2px;
    background: rgba( 0, 0, 0, .6 );
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}


