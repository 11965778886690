.App {
  text-align: center;
}

.App-logo {
  /* animation: App-logo-spin infinite 20s linear; */
  height: 250px;
  margin: 2em;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

div.mobile__pdf__container > div#viewerContainer {
    position: relative;
    top: 0;
}

.mobile__pdf__container > .pdfViewer {
    position: relative;
}

.video-container {
    position:relative;
}

.video {
    height:100%;
    width:100%;
    max-width:600px;
}

.localVideo {
    width: 120px;
    height: 120px;
    position: absolute;
    top:0;
    left:0;
    z-index: 1;
}

.remoteVideo {
    width:100%;
    max-width:600px;
    position: relative;
}

.localVideo > video {
    width: 100%;
}

.remoteVideo > video {
    width: 100%;
}

.label {
    font-family: sans-serif;
    padding: 2px;
    background: rgba( 0, 0, 0, .6 );
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

